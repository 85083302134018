@charset "utf-8";
@import "settings";
@import "assets/vendor/zurb/foundation/scss/foundation";
@import "node_modules/motion-ui/src/motion-ui";
@import "flaticon";
@import "slick";
@import "_slick-lightbox";
// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;
// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
@include foundation-flex-grid;
// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
@include foundation-table;
// Basic components
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
//@include foundation-pagination;
//@include foundation-tooltip;
// Containers
@include foundation-accordion;
@include foundation-media-object;
//@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;
// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;
// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;
// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;
// FOR IE 10
@-ms-viewport {
  width: device-width;
}
.lt-ie10 .left-off-canvas-menu {
  left: -250px;
}
.lt-ie10 .move-right > .inner-wrap {
  left: 250px;
}
// FONTS
//////////////////////////////////////////////////////////////////////////
@font-face {
  font-family: 'Futura Std';
  font-display: swap;
  src: url("../fonts/FuturaStd-Light.woff2") format("woff2"), url("../fonts/FuturaStd-Light.woff") format("woff"), url("../fonts/FuturaStd-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Std';
  font-display: swap;
  src: url("../fonts/FuturaStd-Book.woff2") format("woff2"), url("../fonts/FuturaStd-Book.woff") format("woff"), url("../fonts/FuturaStd-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Std';
  font-display: swap;
  src: url("../fonts/FuturaStd-Bold.woff2") format("woff2"), url("../fonts/FuturaStd-Bold.woff") format("woff"), url("../fonts/FuturaStd-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'SortsMillGoudy';
  font-display: swap;
  src: url("../fonts/sortsmillgoudy-regular.woff2") format("woff2"), url("../fonts/sortsmillgoudy-regular.woff") format("woff"), url("../fonts/sortsmillgoudy-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SortsMillGoudy';
  font-display: swap;
  src: url("../fonts/sortsmillgoudy-italic.woff2") format("woff2"), url("../fonts/sortsmillgoudy-italic.woff") format("woff"), url("../fonts/sortsmillgoudy-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@import url('https://fonts.googleapis.com/css?family=Lato:300,400&&display=swap');
// MIXINS
//////////////////////////////////////////////////////////////////////////
@mixin mq($media-query) {
  //matches Foundation default breakpoints
  // Greater than 320px (not in Foundation) //
  $mq-small-screen: "(min-width: 21em)";
  // Greater than 640px //
  $mq-medium-screen: "(min-width: 40em)";
  // Greater than 1024px //
  $mq-large-screen: "(min-width: 64em)";
  // Greater than 1440px (GRID) //
  $mq-xlarge-screen: "(min-width: 90em)";
  // Greater than 1920px (GRID) //
  $mq-xxlarge-screen: "(min-width: 120em)";
  // up to 800px high //
  $mq-laptop-screen: "(max-height: 56.250)";
  @if $media-query == mq-small {
    @media #{$mq-small-screen} {
      @content;
    }
  } @else if $media-query == mq-medium {
    @media #{$mq-medium-screen} {
      @content;
    }
  } @else if $media-query == mq-large {
    @media #{$mq-large-screen} {
      @content;
      //anything larger than iPad landscape start to add desktop functionality (ie. fixed backgrounds, cover, parallax etc.)
    }
  } @else if $media-query == mq-xlarge {
    @media #{$mq-xlarge-screen} {
      @content;
    }
  } @else if $media-query == mq-xxlarge {
    @media #{$mq-xxlarge-screen} {
      @content;
    }
  } @else if $media-query == mq-laptop {
    @media #{$mq-laptop-screen} {
      @content;
    }
  }
}
@function f-header-size($breakpoint, $size) {
  @return rem-calc(map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-size") * 1px);
}
@mixin header-size($type) {
  font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
  @include breakpoint(medium) {
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
  }
}
@mixin stak {
  // defaults;
  padding: 20px 0;
  p {
    font-size: 1em;
    &.lead {
      font-size: 100%;
      line-height: 1.6;
    }
  }
  @include mq(mq-medium) {
    // enhancements;
    padding: 40px 0;
  }
  @include mq(mq-large) {
    // enhancements;
    padding: 60px 0;
    p {
      font-size: 1em;
      &.lead {
        font-size: 125%;
        line-height: 1.6;
      }
    }
  }
  @include mq(mq-xlarge) {
    //enhancements;
    padding: 80px 0;
  }
}
@mixin hero-slide {
  // defaults;
  height: 380px;
  background-repeat: no-repeat;
  background-size: cover !important;
  z-index: 0;
  overflow: hidden;
  display: block;
  background-position: center center;
  backface-visibility: hidden;
  @include mq(mq-medium) {
    // enhancements;
    height: 400px;
  }
  @include mq(mq-large) {
    // enhancements;
    height: 700px;
    //background-attachment: fixed;
  }
  @include mq(mq-xlarge) {
    // enhancements;
    height: 900px;
  }
  @include mq(mq-xxlarge) {
    // enhancements;
    height: 1000px;
  }
  @include mq(mq-laptop) {
    // enhancements;
    height: 400px;
  }
}
@mixin case-slide {
  // defaults;
  //height: 380px;
  background-repeat: no-repeat;
  background-size: cover !important;
  z-index: 0;
  overflow: hidden;
  display: block;
  background-position: center center;
  backface-visibility: hidden;
  @include mq(mq-medium) {
    // enhancements;
    height: 400px;
  }
  @include mq(mq-large) {
    // enhancements;
    height: 700px;
    //background-attachment: fixed;
  }
  @include mq(mq-laptop) {
    // enhancements;
    height: 400px;
  }
}
@mixin no-bullet {
  list-style-type: none;
  margin: 0;
}
@mixin stak-pic {
  // @include stak;
  // min-height: 300px;
  background-color: transparent;
  position: relative;
  //background-position: center center !important;
  //background-repeat: no-repeat;
  @include mq(mq-large) {
    //min-height: 400px;
    //background-attachment: fixed;
    //background-size: cover !important;
  }
  h2 {
    color: $white;
    line-height: initial;
    span {
      background-color: rgba($body-font-color, 0.8);
      padding: 0 0.25em;
      margin: 0;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
    strong {
      color: $black;
      display: inline-block;
    }
  }
  li,
  p,
  strong {
    color: $white;
  }
  h3 {
    color: $eggshell;
  }
  h4 {
    color: $white;
    margin: 0;
  }
  em {
    color: $gray;
    font-size: 1.25em;
    font-family: $font-family-serif;
  }
  a {
    color: lighten($blue, 30%);
    &:hover {
      text-decoration: underline;
    }
  }
  i:before {
    color: $white;
  }
  .columns {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @include mq(mq-medium) {
    .columns {
      padding-top: 50px;
      padding-bottom: 50px;
      height: 100%;
    }
  }
}
@mixin fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  left: 0;
  &.expanded:not(.top-bar) {
    height: auto;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
  }
  &.expanded:not(.top-bar) .title-area {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  &.expanded:not(.top-bar) .top-bar-section {
    margin-top: 5rem;
    z-index: 98;
  }
}
@mixin radius {
  border-radius: 3px;
}
.foo {
  @include grid-row;
  .bar {
    @include grid-column(12);
  }
}
@media screen and #{breakpoint(medium)} {
  .foo {
    @include grid-row;
    .bar {
      @include grid-column(4);
    }
  }
}
// DEFAULTS (* see _settings.scss for FOUNDATION OVERRIDES)
//////////////////////////////////////////////////////////////////////////
address {
  font-style: normal;
  color: $gray;
  margin-bottom: 2em;
  strong {
    color: $black;
  }
  &:after {
    content: "";
    width: 100px;
    height: 4px;
    background-color: $green;
    position: absolute;
  }
}
.columns {
  position: relative;
}
.fixed {
  position: fixed;
  width: 100%;
  z-index: 1;
}
.float-right {
  float: right;
}
.horz-rule {
  display: block;
}
$off-canvas-width: 60%;
.row.expanded {
  max-width: none;
  > .columns {
    padding: 0;
  }
}
:focus {
  outline: none;
}
h2,
h3,
h4 {
  font-weight: bold;
}
@include mq(mq-medium) {
  h2,
  h3,
  h4 {
    font-weight: 500;
  }
}
h2 {
  margin-bottom: .25em;
  line-height: 1.15em;
}
h3 {
  color: $gray;
  font-weight: 400;
  line-height: 1.15em;
  &.subheader {
    font-weight: 300;
    color: $gray;
  }
}
.header {
  h3 {
    font-weight: 300;
  }
  @include mq(mq-medium) {
    h3 {
      line-height: 1.4em;
      padding: 0 10%;
      font-size: f-header-size(small, h3) + 0.25;
    }
    p {
      padding: 0 10%;
    }
  }
  margin-top: 1em;
}
main {
  a {
    p {
      color: lighten($body-font-color, 30);
    }
    &:hover {
      p {
        color: $body-font-color;
      }
    }
  }
}
a.has-tip {
  border-bottom: none;
  color: $primary-color;
  cursor: pointer;
  font-weight: normal;
}
label:not([for]) {
  font-size: 1em;
  font-weight: bold;
}
input[type="radio"] + label {
  margin-left: 0;
  margin-right: 0.5em;
}
// images
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  &.contain-media {
    width: 100%;
  }
  &.img-right {
    margin-bottom: 20px;
    width: 100%;
    max-width: auto;
    @include mq(mq-medium) {
      float: right;
      margin-left: 60px;
      max-width: 100%;
      width: auto;
    }
  }
  &.img-left {
    float: left;
  }
  &.no-scale {
    width: initial !important;
    max-width: none;
  }
}
@include mq(mq-large) {
  .v-center {
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    top: 50%;
    position: absolute;
  }
}
.slick-arrow {
  z-index: 1;
}
.go-button {
  @include button;
  //background-color: $sage;
  //font-weight: 300;
  @include header-size(h5);
  margin-top: 1em;
  color: $white !important;
  //letter-spacing: 2px;
  overflow: hidden;
  //height: 50px;
  width: 250px;
  cursor: pointer;
  white-space: nowrap;
  transition-timing-function: linear;
  transition: all 0.2s ease;
  margin: 0 auto 1em;
  position: relative;
  &:hover {
    //background-color: $sage;
  }
  &:before {
    content: '';
    background: darken($primary-color, 10%) url("../images/site/arrowhead.png") center center no-repeat;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translateZ(0) scale(1, 1.1) translate(-101%, 0);
    -ms-transform: translateZ(0) scale(1, 1.1) translate(-101%, 0);
    transform: translateZ(0) scale(1, 1.1) translate(-101%, 0);
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }
  &:hover:before {
    -webkit-transform: translateZ(0) scale(1, 1.1) translate(0%, 0);
    -ms-transform: translateZ(0) scale(1, 1.1) translate(0%, 0);
    transform: translateZ(0) scale(1, 1.1) translate(0%, 0);
  }
}
ul.link-list {
  list-style: none;
  margin: 0 auto;
  li:after {
    content: "\203A \20";
    padding-left: 0.25em;
  }
  li:hover:after {
    color: $orange;
  }
  a {
    display: inline-block;
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 0;
      margin: 0.25em 0 0;
      background: transparent;
      transition: width 0.25s ease, background-color 0.25s ease;
    }
    &:hover:after {
      width: 100%;
      background: $orange;
    }
  }
}
.stak-pic {
  @include stak-pic;
}
.graphite {
  color: $graphite;
}
.gray {
  color: $gray;
}
.eggshell {
  color: $eggshell;
}
.red {
  color: $red;
}
.orange {
  color: $orange;
}
.lime {
  color: $lime;
}
.blue {
  color: $blue;
}
.navy-blue {
  color: $navy-blue;
}
.green {
  color: $green;
}
.sage {
  color: $sage;
}
.umber {
  color: $umber;
}
.bgd-graphite {
  background-color: $graphite;
}
.bgd-gray {
  background-color: $gray;
}
.bgd-eggshell {
  background-color: $eggshell;
}
.bgd-red {
  background-color: $red;
}
.bgd-orange {
  background-color: $orange;
}
.bgd-lime {
  background-color: $lime;
}
.bgd-navy-blue {
  background-color: $navy-blue;
}
.bgd-green {
  background-color: $green;
}
.bgd-sage {
  background-color: $sage;
}
.bgd-umber {
  background-color: $umber;
}
.button-group {
  &.round {
    a {
      border-right: lighten($primary-color, 10%) solid 1px;
      margin-right: 0;
    }
    a:first-of-type {
      border-top-left-radius: 2.375em;
      border-bottom-left-radius: 2.375em;
    }
    a:last-of-type {
      border-top-right-radius: 2.375em;
      border-bottom-right-radius: 2.375em;
      border-right: none;
    }
  }
}
.lead {
  font-size: 100%;
  @include breakpoint(medium) {
    font-size: 105%;
  }
  @include breakpoint(large) {
    font-size: 125%;
  }
}
.compass-cursor {
  cursor: url("/assets/images/site/cursor-compass.svg"), auto;
}
.collapse-bottom {
  padding-bottom: 0!important;
}
sup{
  vertical-align: super;
  font-size:45%;
}
// GLOBALS (CUSTOM GLOBALS)
//////////////////////////////////////////////////////////////////////////
header.main-nav {
  background-color: rgba($graphite, 0);
  transition: all 0.25s;
  position: relative;
  .logo {
    width: 150px;
    margin: 0.85em 1em 0 0.5em;
    transition: all 0.25s;
    svg {
      .cls-1 {
        fill: #fff;
        transition: all 0.25s;
      }
    }
    &:hover {
      svg {
        .cls-1 {
          fill: $primary-color;
        }
      }
    }
  }
  @include mq(mq-medium) {
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
    .logo {
      margin: 0.85em 2em 0 0.5em;
    }
  }
  @include mq(mq-xlarge) {
    // enhancements;
    .logo {
      margin: 0.85em 2em 0 0.5em;
    }
  }
  // MEGA-MENU ///////////////////////////////////////////////
  ul.mega-menu {
    text-align: right;
    float: right;
    > li {
      float: left;
    }
    > li > a, > li > button {
      font-size: 0.85em;
      padding: 0 1em;
      color: $white;
      line-height: 5rem;
      text-transform: uppercase;
      font-weight: normal;
      transition: all 0.5s;
      &:hover {
        cursor: pointer;
      }
      &:before {
        content: '';
        display: block;
        height: 3px;
        width: 0;
        margin: 0;
        background: transparent;
        transition: width 0.25s ease, background-color 0.25s ease;
      }
      &:hover:before {
        width: 100%;
        background: $orange;
      }
      &.sel {
        &:before {
          width: 100%;
          background: $white;
        }
      }
    }
    > li:first-of-type > a {
      padding: 0 1em 0 0;
    }
    /* Remove content top border, align to left */
  }
  &.shrink {
    background-color: rgba($graphite, 0.9);
    height: auto;
    line-height: 2em;
    ul.mega-menu > li > a, ul.mega-menu > li > button {
      //color: $black;
      line-height: 3.5em;
      &:hover {
        color: $primary-color;
      }
    }
    .logo {
      width: 100px;
      margin: 0.5em 1em 0 0;
      svg {
        height: 30px;
        .cls-1 {
          //fill: $black;
        }
      }
      &:hover {
        svg {
          .cls-1 {
            fill: $primary-color;
          }
        }
      }
    }
    @include mq(mq-large) {
    }
    @include mq(mq-xlarge) {
    }
  }
}
.mega-menu-content {
  list-style: none;
  font-size: 0.875rem;
  max-height: none;
  padding: 2em 2em 0;
  width: 100% !important;
  // top: 0 !important;
  // left: 0 !important;
  // right: 0 !important;
  text-align: left;
  // opacity: 0;
  transition: all 0.5s;
  background: rgba($white, 0.98) url("/assets/images/site/bgd-topo.webp") repeat;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  // .close-reveal-modal {
  //    position: absolute;
  //    top: 10px;
  //    right: 10px;
  //    font-size: 2em;
  // }
  span {
    border: 1px solid $primary-color;
    padding: 1em 1.5em;
    line-height: 1em;
    border-radius: 50px;
    width: 100%;
    text-align: center;
    display: inline-block;
  }
  small {
    color: rgba($graphite, 0.98);
  }
  p {
    font-size: 1em;
    //color: $silver;
    color: rgba($graphite, 0.98);
    margin-bottom: 0;
    &.lead {
      font-size: 1.05em;
      //color: $silver;
      margin-bottom: 1em;
      a {
        line-height: inherit !important;
        //color: $silver;
        color: $primary-color;
      }
    }
  }
  ul.link-list li {
    margin: 0.25em 0 0.75em;
    // text-align: left;
    position: relative;
    float: none;
    // &:before {
    //     position: absolute;
    //     line-height: 1.25em!important;
    // }
    &:after {
      display: none !important;
    }
    a:not(.button) {
      line-height: 1.25em !important;
      font-size: 1em;
      display: inline-block;
      // padding-left: 0.6em !important;
      img {
        margin: 0.75em 0;
        display: block;
      }
      &:hover {
        img {
          opacity: 0.9;
        }
      }
    }
  }
  .co-block {
    text-align: center;
    line-height: 1.25em !important;
    h3 {
      @include header-size(h5);
      line-height: 1.15em;
    }
    a {
      line-height: 1.25em !important;
      //color: $eggshell;
      .ico-img {
        width: 30%;
        max-height: 80px;
        transition: all 0.25s;
        transform: scale(1, 1);
        .cls-1,
        .cls-2 {
          fill: $graphite;
          transition: all 0.5s;
        }
        @include mq(mq-large) {
          width: 40%;
        }
      }
      img {
        transition: all 0.25s;
        transform: scale(1, 1);
      }
      h3 {
        color: $gray;
        transition: all 0.5s;
      }
      &:hover {
        color: $primary-color;
        .ico-img {
          transform: scale(1.1, 1.1);
          .cls-2 {
            fill: $red;
          }
        }
        img {
          transform: scale(1.1, 1.1);
        }
        h3 {
          color: $primary-color;
        }
      }
    }
  }
  .extra {
    border-right: 1px solid rgba($gray, 0.25);
    padding-right: 2em;
    margin-bottom: 1em;
    &:first-of-type {
      .columns {
        padding-right: 0;
      }
    }
    &:last-of-type {
      border-right: none;
      box-shadow: none;
    }
  }
  .button {
    top: initial;
    height: initial;
  }
  &.is-open {
    opacity: 1;
    border: none;
    margin: 0;
  }
}
/* Remove Foundation dropdown arrow */
.dropdown-pane:after,
.dropdown-pane:before {
  border-style: none;
}
/* Remove hover state and background color from content headings */
.content[class*="content"] label,
.content[class*="content"] label:hover {
  font-weight: bold;
  font-size: 1.25em;
}
// MOBILE NAVIGATION
/////////////////////////////////////
.title-bar {
  background-color: $white;
  text-align: center;
  padding: 0.5em 0 0.25em;
  line-height: 1em;
  .logo {
    width: 120px;
    //margin: 0 auto;
    padding: 0 0 0 1em;
    .fill-black {
      fill: $graphite;
    }
  }
  .menu-ico {
    //margin: -2px 0.3em;
    border: none;
    background: none;
    z-index: 13;
    transform: scale3d(0.8, 0.8, 0.8);
    transition: 0.3s;
    cursor: pointer;
    user-select: none;
    position: absolute;
    right: 10px;
    top: 1em;
    span {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $green;
      border-radius: 0.5em;
      transition: 0.3s;
      position: relative;
    }
    span:before {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $green;
      border-radius: 0.5em;
      transition: all 0.3s;
      position: absolute;
      top: 0.75em;
      left: 0;
      content: '';
      transform-origin: 0.13rem center;
    }
    span:after {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $green;
      border-radius: 0.5em;
      transition: all 0.3s;
      position: absolute;
      top: -.75em;
      left: 0;
      content: '';
      transform-origin: 0.13rem center;
      //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    }
    &:hover {
      span {
        background: lighten($green, 10%);
      }
      span:before {
        background: lighten($green, 10%);
      }
      span:after {
        background: lighten($green, 10%);
        //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
      }
    }
  }
}
.menu-ico[aria-expanded="true"] {
  //background-color: $primary-color;
  span {
    background: transparent !important;
  }
  span:before {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, 45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $primary-color;
  }
  span:after {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, -45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $primary-color;
  }
}
.right-off-canvas-menu {
  padding-top: 3.75rem;
  //background-color: rgba($jet, .9);
  .menu li a {
    display: block;
    border-bottom-color: darken($blue, 10%);
    border-top: 1px solid rgba($white, 0.15);
    color: $white;
    &:hover {
      color: $primary-color;
    }
  }
  .menu li:first-of-type {
    a {
      border-top: none;
    }
  }
  .menu li:last-of-type {
    a {
      border-bottom: none;
    }
  }
}
// GLOBAL FOOTER
/////////////////////////////////////
footer.global-footer {
  background-color: $eggshell;
  padding: 0;
  color: #fff;
  .footer-form {
    text-align: center;
    background-color: $graphite;
    border-bottom: 1px solid lighten($graphite, 10%);
    input, textarea {
      background-color: transparent !important;
      color: $white !important;
      border-bottom: 1px solid lighten($graphite, 20%);
      background-image: none !important;
      &::placeholder {
        color: lighten($graphite, 50%);
      }
    }
    textarea {
      border: 1px solid lighten($graphite, 20%);
    }
    .is-invalid-input:not(:focus)::placeholder {
      color: $white;
    }
    select {
      border: none;
      border-bottom: 1px solid lighten($graphite, 20%);
      box-shadow: none;
      transition: all .25s;
      background-color: transparent !important;
      color: lighten($graphite, 50%);
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28228,42,5%29'></polygon></svg>");
      &:focus {
        border: none;
        border-bottom: 3px solid $lime !important;
        box-shadow: none !important;
        transition: all .25s;
      }
    }
    ul.errors {
      margin: 0;
      li.form-error {
        display: block;
        &:before {
          content: "*";
        }
      }
    }
  }
  ul.accordion {
    margin: 0;
  }
  .accordion .accordion-item {
    text-align: center;
    background-color: $orange;
    border-bottom: 1px solid lighten($orange, 10%);
    &:last-of-type {
      border-bottom: none;
    }
    > a.accordion-title {
      font-size: 1.1em;
      white-space: nowrap;
      border: none;
      transition: all 0.5s;
      color: $eggshell;
      @include mq(mq-large) {
        font-size: 1.25em;
      }
      @include mq(mq-xlarge) {
        font-size: 1.5em;
      }
      padding: 3em 0;
      background-color: $orange;
      span {
        border: 1px solid rgba($white, 0.8);
        padding: 0.5em 1em;
        border-radius: 50px;
      }
      &:hover {
        background-color: darken($orange, 8%);
      }
      @include mq(mq-medium) {
        margin: auto;
        text-transform: uppercase;
        padding: 6em;
      }
      &.active {
        > a {
          color: $eggshell;
          background-color: darken($orange, 5);
        }
      }
      &:hover {
        span {
          border: 2px solid rgba($white, 1);
        }
      }
    }
    > .content {
      color: $jet;
      background-color: $eggshell;
      //padding: 2em 0;
      form {
        padding: 2em 0 0;
        input {
          height: 40px;
          font-size: 1.25em;
          color: $gray;
        }
      }
      a {
        color: $green;
        white-space: nowrap;
      }
    }
  }
  .copyright {
    margin: 1em auto;
    text-align: center;
    p {
      margin: 0 0 1em;
      font-size: 0.7em;
      font-family: $header-font-family;
      color: $monsoon;
      vertical-align: text-bottom;
      display: block;
    }
    .tagline {
      display: block;
    }
    .bugs {
      font-size: 1em;
      display: block;
      a {
        color: $gray;
        text-align: center;
        margin: .5em 0 0 0;
        height: 1em;
        &:hover {
          color: $primary-color;
        }
      }
    }
    .logo {
      width: 95px;
      vertical-align: text-bottom;
      margin: 0 auto;
      svg {
        .cls-1 {
          fill: $gray;
        }
      }
      &:hover {
        svg {
          .cls-1 {
            fill: $primary-color;
          }
        }
      }
    }
    @include mq(mq-large) {
      text-align: right;
      height: 45px;
      p {
        font-size: 0.75em;
        display: inline-block;
        margin: 0 1em 0 0;
      }
      .bugs {
        display: inline-block;
      }
      .logo {
        display: inline-block;
      }
      .tagline {
        display: inline-block;
        margin-left: .5em;
      }
    }
  }
  .callouts {
    margin-top: 2em;
    @include mq(mq-medium) {
      margin-top: 0;
    }
    .callout {
      h4 {
        @include header-size(h5);
        color: $jumbo;
        text-transform: uppercase;
        border-bottom: 1px solid lighten($jet, 5);
      }
      p {
        font-size: 0.75em;
      }
    }
  }
}
.icon-callouts {
  img {
    padding-right: 0.5em;
  }
  a:hover {
    h4 {
      color: $primary-color;
    }
  }
}
// HOMEPAGE
//////////////////////////////////////////////////////////////////////////
h3.typed-header {
  line-height: 1.25em;
}
.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
.typed-fade-out {
  opacity: 0;
  animation: 0;
  transition: opacity 0.5s;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#home-top-bar {
  width: 100%;
}
.hero {
  @include mq(mq-large) {
  }
  > h2 {
    display: none;
  }
}
@include breakpoint(large) {
  .mountaineer {
    @include hero-slide;
    position: fixed;
    width: 100%;
    display: block;
    background-size: cover;
    background-image: url("/assets/images/site/home-hero-mountaineering-a.webp");
    background-position: 50% 0;
    transform-origin: center bottom;
  }
}
.home-hero {
  margin: 0;
  backface-visibility: hidden;
  position: relative;
  background: linear-gradient(to bottom, #003e98 0%, #39a5df 50%, white 100%);
  .arrow-down {
    display: none;
    @include mq(mq-xlarge) {
      display: none;
      position: absolute;
      margin: 0 auto;
      right: 0;
      bottom: 100px;
      left: 0;
      width: 60px;
      height: 60px;
      background: url("/assets/images/site/arrow-down.png") center center no-repeat;
      z-index: 9999;
      a {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  .hero-caption {
    position: absolute;
    z-index: 1;
    //top: 0;
    right: 0;
    left: 0;
    width: 90%;
    text-align: center;
    padding: 100px 0 0;
    .ico-aw {
      max-width: 70px;
      margin-bottom: 10px;
      opacity: 0.75;
      transition: all 0.5s;
      &:hover {
        opacity: 1;
      }
      @include mq(mq-large) {
        max-width: 100px;
      }
    }
    h1 {
      font-weight: 900;
      margin: 0;
      text-transform: uppercase;
    }
    h2 {
      font-weight: 200;
      margin: 0;
    }
    h1,
    h3 {
      color: white;
      line-height: 1em;
    }
    h2 {
      color: white;
      line-height: normal;
    }
    p {
      font-size: $paragraph-font-size;
      margin-top: 1em;
      a {
        color: white;
        font-weight: 700;
      }
    }
    .button {
      @include button;
      margin-top: 2em;
    }
  }
  @include mq(mq-medium) {
    .hero-caption {
      padding: 120px 0;
      p {
        font-size: $paragraph-font-size + 0.25;
      }
      .button {
        @include button;
        margin-top: 2em;
      }
    }
  }
  @include mq(mq-large) {
    .hero-caption {
      padding: 220px 0 0;
      h1 {
        font-size: f-header-size(medium, h1) + 0.5;
      }
      h2 {
        @include header-size(h2);
      }
    }
  }
  @include mq(mq-xlarge) {
    .hero-caption {
      padding: 300px 0 0;
      h1 {
        font-size: f-header-size(medium, h1) + 1.25;
      }
      h2 {
        font-size: f-header-size(medium, h2) + 1;
      }
    }
  }
  @include mq(mq-xxlarge) {
    .hero-caption {
      padding: 350px 0 0;
    }
  }
}
.carousel-hero {
  margin-top: 0;
  margin-left: 0;
  display: none;
  opacity: 0;
  transition: opacity 0.25s ease-in;
  background-color: transparent;
  &.slick-initialized {
    opacity: 1;
    display: block;
  }
  @include hero-slide;
  @include mq(mq-xlarge) {
    .slick-next,
    .slick-prev {
      top: 50%;
    }
  }
  // .slick-slide class is jQuery generated
  .slick-slide {
    @include hero-slide;
    position: relative;
    clip-path: polygon(0 0, 0 95%, 45% 95%, 50% 100%, 55% 95%, 100% 95%, 100% 0);
    @media #{large} {
      clip-path: polygon(0 0, 0 97%, 48% 97%, 50% 100%, 52% 97%, 100% 97%, 100% 0);
    }
    //background-color: $vapor;
  }
  .slide-caption {
    @include grid-column(12);
    position: absolute;
    bottom: 30px;
    right: 0;
    left: 0;
    font-size: $paragraph-font-size - 0.25;
    text-align: center;
    p {
      background-color: rgba($white, 0.9);
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      padding: 1em;
      display: inline;
      border: 1px solid rgba($white, 0.8);
    }
  }
  @include mq(mq-medium) {
    .slide-caption {
      font-size: $paragraph-font-size;
    }
  }
}
.home-lead {
  padding-bottom: 60px;
}
.awards {
  margin: 0 auto;
  text-align: center;
  img {
    width: 100% !important;
    max-width: 80px;
    margin: 1em auto;
    filter: grayscale(100%);
    transition: all .25s ease-in-out;
    &:hover {
      filter: grayscale(0%);
    }
  }
  @include breakpoint(medium) {
    img {
      max-width: 130px;
    }
  }
}

.page-hero {
  margin: 0;
  position: relative;
  .hero-caption {
    position: absolute;
    z-index: 1;
    width: 90%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      font-weight: 900;
      margin: 0;
      text-transform: uppercase;
    }
    h2 {
      font-weight: 200;
      margin: 0;
    }
    h1,
    h3 {
      color: $white;
      line-height: 1em;
    }
    h2 {
      color: white;
      line-height: normal;
    }
    p {
      font-size: $paragraph-font-size;
      margin-top: 1em;
      color: $white;
      a {
        color: white;
        font-weight: 700;
      }
    }
    &.article-caption {
      p {
        font-size: .75em;
      }
    }
  }
  @include mq(mq-medium) {
    .hero-caption {
      p {
        font-size: $paragraph-font-size;
      }
      &.article-caption {
        p {
          font-size: $paragraph-font-size;
        }
      }
    }
  }
  @include mq(mq-large) {
    .hero-caption {
      h1 {
        font-size: f-header-size(medium, h1) + 0.5;
      }
      h2 {
        @include header-size(h2);
      }
    }
  }
  @include mq(mq-xlarge) {
    .hero-caption {
      h1 {
        font-size: f-header-size(medium, h1) + 1.25;
      }
      h2 {
        font-size: f-header-size(medium, h2) + 1;
      }
    }
  }
  .hero-image {
    @include hero-slide;
    position: relative;
    clip-path: polygon(0 0, 0 95%, 45% 95%, 50% 100%, 55% 95%, 100% 95%, 100% 0);
    @media #{large} {
      clip-path: polygon(0 0, 0 97%, 48% 97%, 50% 100%, 52% 97%, 100% 97%, 100% 0);
    }
    background-color: $light-gray;
    &.article-hero-image {
      height: 240px;
      @include mq(mq-medium) {
        // enhancements;
        height: 400px;
      }
      @include mq(mq-large) {
        // enhancements;
        height: 500px;
        background-size: initial !important;
        background-position: center center !important;
      }
      @include mq(mq-xlarge) {
        // enhancements;
        height: 600px;
      }
    }
    &.casestudy, &.landing-hero {
      @include mq(mq-large) {
        // enhancements;
        height: 500px;
      }
      @include mq(mq-xlarge) {
        // enhancements;
        height: 600px;
      }
    }
  }
}
.stak {
  @include stak;
  //  background-color: $white;
  &.no-bottom {
    padding-bottom: 0;
  }
}
.alt-stak {
  @include stak;
  background-color: $snow;
  background: linear-gradient(to bottom, $snow 0%, white 100%);
}
.rollover-callouts {
  margin: 0 auto;
  // -webkit-box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.75);
  // box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
  .columns {
    padding: 0;
  }
  h3 {
    line-height: 1.15em;
    @include mq(mq-medium) {
      font-size: f-header-size(medium, h4);
    }
    @include mq(mq-large) {
      // font-size: f-header-size(medium, h4) + 0.25;
    }
  }
  a {
    .ico-img {
      margin: 0 auto;
      width: 40%;
      transition: all 0.25s;
      transform: scale(1, 1);
      .cls-1,
      .cls-2 {
        fill: $graphite;
        transition: all 0.5s;
      }
    }
    img {
      transition: all 0.25s;
      transform: scale(1, 1);
    }
    h3 {
      color: $gray;
      transition: all 0.5s;
    }
    &:hover {
      .ico-img {
        transform: scale(1.1, 1.1);
        .cls-2 {
          fill: $red;
        }
      }
      img {
        transform: scale(1.1, 1.1);
      }
      h3 {
        color: $graphite;
      }
    }
  }
  div.rollover {
    margin: 0;
    padding: 0;
    vertical-align: bottom;
    transition: all 0.5s;
    position: relative;
    background-size: cover !important;
    article {
      background-color: rgba($white, 0.5);
      padding: 1em;
      height: 100%;
    }
    &.point-left {
      box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      padding: 1em;
      background-color: $white;
    }
    &.point-right {
      box-shadow: inset 0px 0 10px 0 rgba(0, 0, 0, 0.5);
      padding: 1em;
      background-color: $white;
    }
    .button-group {
      margin-bottom: 0;
      display: block;
    }
    .meta
    h3 {
      color: $graphite;
    }
    .meta {
      height: 100%;
      text-align: center;
      h2 {
        font-weight: 300;
      }
      h3 {
        display: inline-block;
        font-weight: 700;
        text-transform: uppercase;
        color: $black;
      }
    }
    @include mq(mq-medium) {
      min-height: 320px;
      overflow: hidden;
      background-size: cover !important;
      article {
        background-color: rgba($graphite, 0.15);
        transition: all 0.5s;
        height: 100%;
        padding: 1em;
      }
      &.point-left {
        clip-path: polygon(0% 0, 0% 45%, 2% 50%, 0% 55%, 0% 100%, 100% 100%, 100% 0%);
        box-shadow: inset 10px 0 20px 0 rgba(0, 0, 0, 0.5);
        padding: 0;
        background: url('/assets/images/site/ro-calculated-creation.webp');
      }
      &.point-right {
        clip-path: polygon(98% 50%, 100% 45%, 100% 0, 0 0, 0 100%, 100% 100%, 100% 55%);
        box-shadow: inset -10px 0 20px 0 rgba(0, 0, 0, 0.5);
        padding: 0;
        background: url('/assets/images/site/ro-managed-performance.webp');
      }
      .meta {
        transform: translate(0px, 40%);
        transition: all 0.25s;
        h2 {
          color: $white;
          font-size: 36px;
          text-transform: uppercase;
          font-weight: 700;
        }
        h3 {
          color: $white;
          font-size: 36px;
          opacity: 0;
          transition-delay: 0;
        }
        p {
          opacity: 0;
          transition-delay: 0;
          color: $eggshell;
        }
        a {
          opacity: 0;
          transition-delay: 0;
        }
      }
      &:hover {
        //cursor: pointer;
        article {
          background-color: rgba($blue, 0.75);
        }
        .meta {
          // margin-top: 20px;
          transform: translate(0px, 20%);
          transition-delay: 0s;
          h2 {
            opacity: 0;
            //margin-top: -2em;
            font-size: 1px;
          }
          h3 {
            color: $white;
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.25s;
          }
          p {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.25s;
          }
          a {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0s;
          }
        }
      }
    }
    @include mq(mq-large) {
      min-height: 405px;
      &:hover {
        //cursor: pointer;
        .meta {
          h2 {
            //margin-top: -2em;
          }
        }
      }
    }
    &:hover {
      background-size: 120%;
    }
  }
  .co-block-container {
    display: table;
  }
  .co-block {
    padding: 30px 0;
    text-align: center;
    .link-list {
      font-size: 0.8em;
    }
    h3 {
      line-height: 1.15em;
    }
    @include mq(mq-medium) {
      @include radius;
      padding: 10px;
      margin: 10px auto;
      margin-top: 30%;
      //background-color: $snow;
      //box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
      h3 {
        font-size: f-header-size(medium, h4);
      }
    }
    @include mq(mq-large) {
      margin-top: 10%;
      .link-list {
        font-size: 1em;
      }
      padding: 20px 10px;
      h3 {
        font-size: f-header-size(medium, h4) + 0.25;
      }
    }
    ul {
      margin-left: 0;
      li {
        margin-bottom: 0.5em;
        line-height: 1.25em;
      }
      @include mq(mq-large) {
        li {
          margin-bottom: 1em;
        }
      }
    }
  }
}
.rollover-callouts {
  &.topo {
    background: url("/assets/images/site/bgd-topo.webp") repeat;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    .co-block {
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
}
.topo-light {
  background-image: url("/assets/images/site/bgd-topo.webp");
  background-repeat: repeat;
}
.topo-dark {
  background-image: url("/assets/images/site/bgd-topo-dark.webp");
  background-repeat: repeat;
  @include stak;
  h2, h3, h4, p {
    color: $white;
  }
  ol, ul {
    color: $light-gray !important;
  }
  @include mq(mq-medium) {
    background-attachment: fixed;
  }
}
.stak-topo-light {
  @include stak;
  background-image: url("/assets/images/site/bgd-topo.webp");
  background-repeat: repeat;
  @include mq(mq-medium) {
    background-attachment: fixed;
  }
}
.table {
  .content {
    padding: 2em;
  }
  @include mq(mq-medium) {
    display: table;
    width: 100%;
    .row {
      display: table-row;
      width: 100%;
    }
    .columns {
      display: table-cell;
      float: none;
    }
    .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
      float: none;
    }
    .vertical-center {
      vertical-align: middle;
    }
  }
  @include mq(mq-large) {
    .content {
      padding: 4em;
    }
  }
}
.collapse {
  .content {
    padding: 2em;
  }
  @include breakpoint(xlarge) {
    .content {
      padding: 4em;
    }
  }
}
// TABS CONTAINER
.tabs {
  display: flex;
  flex-wrap: wrap;
  border: none;
  background-color: transparent;
  margin: 0 auto;
  .tabs-title {
    position: inherit;
    display: inline-block;
    padding: 0.75em 0.5em;
    > a {
      display: block;
      background-color: transparent;
      color: $gray;
      font-family: "futura-pt", Helvetica, Arial, sans-serif;
      font-size: 0.85em;
      padding: 0.75em;
      line-height: 1em;
      border: 1px solid $silver;
      border-radius: 2.375em;
      transition: all 0.25s;
    }
    &.is-active > a {
      background-color: transparent;
      color: $orange;
      border: 1px solid $orange;
    }
    > a:hover {
      background-color: transparent;
      border: 1px solid $orange;
      color: $black;
    }
    @include breakpoint(large) {
      position: inherit;
      display: block;
      padding: 0 0.5em 1em 0;
      text-align: left;
    }
  }
}
.tabs-content {
  border: none;
  background-color: transparent;
  > .tabs-panel {
    text-align: center;
    background-color: transparent;
    h2 {
      line-height: 1em;
      margin-bottom: 0.5em;
    }
    h3 {
      font-family: $font-family-serif;
      font-style: italic;
      color: $gray;
    }
  }
  @include breakpoint(medium) {
    margin: 0 0 1.5em !important;
    > .tabs-panel {
      text-align: left;
    }
  }
  @include breakpoint(large) {
    > .tabs-panel {
      opacity: 0;
      z-index: 0;
      li {
        font-size: 1.25em;
      }
    }
    > .tabs-panel.is-active {
      display: block;
      position: relative;
      opacity: 1;
      z-index: 1;
      margin-left: 0;
      animation: fadeIn;
      animation-duration: 1s;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;
    }
  }
}
.topo-dark {
  .tabs {
    .tabs-title {
      > a:hover {
        color: $white;
      }
    }
  }
}
.work-slider {
  margin: 0 auto;
  width: auto;
  display: block;
  min-height: 300px;
  @include breakpoint(large) {
    width: 500px;
  }
  @include breakpoint(xlarge) {
    position: absolute;
    min-height: 700px;
    width: 700px;
  }
}
.client-logos {
  margin: 20px 0 0 0;
  ul {
    margin: 0;
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      position: relative;
      text-align: center;
      div {
        width: 106px;
        margin: 0;
        padding: 4px;
        float: left;
        position: relative;
        img {
          //width: 106px;
        }
      }
    }
  }
}
.ri-grid {
  margin: 20px auto 0;
  position: relative;
  height: auto;
}
.ri-grid ul {
  list-style: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}
/* Clear floats by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */
.ri-grid ul:after,
.ri-grid ul:before {
  content: '';
  display: table;
}
.ri-grid ul:after {
  clear: both;
}
.ri-grid ul {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
}
.ri-grid ul li {
  -webkit-perspective: 152px;
  -moz-perspective: 152px;
  -o-perspective: 152px;
  -ms-perspective: 152px;
  perspective: 152px;
  margin: 0;
  padding: 0;
  float: left;
  position: relative;
  display: block;
  overflow: hidden;
  //background: $primary-color;
}
.ri-grid ul li a {
  display: block;
  outline: none;
  position: absolute;
  z-index: 1002;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //background: $primary-color;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-background-size: 90% 90%;
  -moz-background-size: 90% 90%;
  background-size: 90% 90%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
/* Grid wrapper sizes */
.ri-grid-size-1 {
  width: 55%;
}
.ri-grid-size-2 {
  width: 100%;
}
.ri-grid-size-3 {
  width: 100%;
  //margin-top: 0;
}
.ri-grid-loading:after,
.ri-grid-loading:before {
  display: none;
}
.ri-loading-image {
  display: none;
}
.ri-grid-loading .ri-loading-image {
  position: relative;
  width: 30px;
  height: 30px;
  left: 50%;
  margin: 100px 0 0 -15px;
  display: block;
}
// SOLUTIONS
//////////////////////////////////////////////////////////////////////////
.iterative-loop {
  margin: 2em auto;
  .infinity {
    fill: url("#linear-gradient");
  }
  .plus {
    fill: $orange;
  }
  .heading {
    fill: $graphite;
    font-size: 1em;
    letter-spacing: 0.02em;
    transform: all 0.5s;
  }
  .subheading {
    font-size: 13px;
    fill: $gray;
    transform: all 0.25s;
  }
  .circle {
    fill: $white;
  }
  a:hover {
    .heading {
      fill: $red;
    }
    .subheading {
      fill: $red;
    }
  }
}
.solution-tabs {
  .tabs {
    border: none;
  }
  .tabs .tabs-title {
    position: inherit;
    display: inline-block;
    padding: 0.75em 0.5em;
    float: none;
    > a {
      display: block;
      background-color: transparent;
      color: $gray;
      font-family: "futura-pt", Helvetica, Arial, sans-serif;
      font-size: 0.8em;
      padding: 0.75em;
      line-height: 1em;
      border: 1px solid $silver;
      border-radius: 2.375em;
      transition: all 0.25s;
      @include mq(mq-medium) {
        font-size: 1.1em;
      }
    }
  }
  .tabs .tabs-title.is-active > a {
    background-color: transparent;
    color: $orange;
    border: 1px solid $orange;
  }
  .tabs .tabs-title > a:hover {
    background-color: transparent;
    border: 1px solid $orange;
  }
  // .tabs .tab-title {
  //     position: inherit;
  //     display: inline-block;
  //     padding: 0 1em;
  //     float: none;
  // }
  // .tabs .tab-title > a {
  //     display: block;
  //     background-color: transparent;
  //     color: $gray;
  //     font-family: "futura-pt", Helvetica, Arial, sans-serif;
  //     font-size: 1.25em;
  //     padding: 0.5em 0 0;
  //     line-height: 1em;
  //
  // }
  // .tabs .tab-title.active > a {
  //     background-color: transparent;
  //     color: $orange;
  //
  // }
  // .tabs .tab-title > a:hover {
  //     background-color: transparent;
  //     color: $orange;
  // }
  .stak {
    border-bottom: 1px solid $snow;
  }
  .tabs-panel {
    h2 {
      line-height: 1em;
      margin-bottom: 0.5em;
    }
    h3 {
      font-family: $font-family-serif;
      font-style: italic;
      color: $gray;
    }
  }
}
.tab-stak {
  padding-top: 100px !important;
  margin-top: -100px;
}
a.page-nav {
  position: fixed;
  text-decoration: none;
  top: 50vh;
  color: $orange;
  padding: 15px;
  z-index: 99;
  transition: 0.5s ease-in-out;
  display: inline-block;
  line-height: 1.25em;
  font-size: 0.8em;
  background-color: rgba($eggshell, 0.8);
  font-weight: bolder;
  div {
    display: inline-block;
    overflow: hidden;
    width: 0;
    vertical-align: text-bottom;
    opacity: 0;
    transition: width 0.25s, opacity 0.5s;
    white-space: nowrap;
    font-weight: normal;
  }
  &:hover {
    color: $white;
    background-color: rgba(darken($orange, 5%), 1);
    div {
      width: 120px;
      opacity: 1;
    }
  }
  &.page-next {
    right: 0;
    padding-right: 8px;
    border-top-left-radius: 2.375em;
    border-bottom-left-radius: 2.375em;
  }
  &.page-prev {
    left: 0;
    padding-left: 8px;
    border-top-right-radius: 2.375em;
    border-bottom-right-radius: 2.375em;
    border-right: none;
  }
}
.quote-slider {
  footer {
    color: lighten($graphite, 50%);
  }
}
.solution-items {
  img {
    margin: 0 auto 1em auto;
  }
}
.rating {
  font-size: .75em;
  .rating-number {
    font-family: $header-font-family;
    font-weight: 600;
    color: $white;
    font-style: normal;
    font-size: 1.5em;
  }
  i {
    color: $orange;
  }
}

.quote-card-slider {
  padding: 0 1em;
  position: relative;
  .slick-slide {
    height: auto;
  }
  .slick-track{
    display: flex!important;
    flex-flow: row wrap;
    align-content: stretch;
    align-items: stretch;
  }
  .card {
    margin: 1em .5em;
    box-shadow: 0 8px 16px -8px $gray;
    border-radius: .5em;
    padding: 1em .5em;
    font-size: .9em;
    text-align: center;
    flex: 1 0 0;
  }
  .rating {
    margin: 1em auto;
  }
  .rating-number {
    font-family: $header-font-family;
    font-weight: 600;
    color: $black;
    font-style: normal;
    font-size: 1.5em;
  }
  blockquote {
    margin: 0 auto;
    text-align: center;
    border-left: none;
    p {
      margin: 0;
      letter-spacing: 0.1em;
      font-family: $body-font-family;
      &:before {
        content: '“';
      }
      &:after {
        content: '”';
      }
    }
    footer {
      margin: 10px 0;
      font-style: italic;
      font-family: $font-family-serif;
    }
    @include mq(mq-medium) {
      //p {
      //  @include header-size(h3);
      //  font-size: 46px;
      //}
    }
  }
  .attributes {
    .att-name {
      display: block;
      font-family: $header-font-family;
      font-weight: bold;
    }
    .att-title {
      display: block;
      font-family: $header-font-family;
      &.bold-title {
        font-weight: bold;
      }
    }
    .att-company {
      color: $gray;
      font-size: .9em;
    }
  }
  .quote-source {
    font-size: .9em;
  }
  .slick-next,
  .slick-prev {
    top: auto;
    bottom: 50%;
    background-color: $transparent;
    z-index: 1;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    //border: 1px solid $primary-color;
    &:before {
      color: $gray;
      font-size: 36px;
      font-weight: 400;
      height: 40px;
      width: 40px;
      text-align: center;
      justify-content: center;
      margin-left: 0;
      margin-left: -8px;
      margin-top: 6px;
    }
    &:hover {
      &:before {
        color: $primary-color;
      }
    }
  }
  .slick-prev {
    right: unset;
    left:-16px;
  }
  .slick-next {
    right:-16px;
    &:before {
      margin-left: -3px;
    }
  }
}

// 2022 Accordions
.accordion {
  margin-top: 2em;
  .accordion-item {
    .accordion-title {
      border: none;
      border-bottom: 1px solid rgba($gray, .25);
      font-weight: bold;
      color: $body-font-color;
      transition: all .25s ease-in-out;
      &:hover, &:focus {
        color: $primary-color;
        background-color: transparent;
        &::before {
          color: lighten($orange, 10%);
          font-weight: bold;
        }
      }
      &::before {
        color: $orange;
        font-size: 1.6em;
      }
      h4 {
        margin-bottom: 0;
      }
    }
    &.is-active {
      .accordion-title {
        background: linear-gradient(to bottom, $snow 0%, white 100%);
        color: $primary-color;
        &::before {
          color: $navy-blue;
        }
      }
    }
    @include breakpoint(medium) {
      .accordion-title {
        padding: 1.5em 1em;
      }
    }
  }
}
// MARKETING LANDING
.module-form {
  textarea {
    height: 8em;
    background-color: $transparent;
  }
  input{
    background-color: $transparent;
  }
}
.form-callout {
padding: 1em;
  background-color: $snow;
  border-radius: .5em;
  box-shadow: 0 8px 16px -8px $gray;
}
// ABOUT
.mission {
  background-image: url("/assets/images/site/climber.png");
  background-position: right top;
  background-repeat: no-repeat;
  dl {
    display: flex;
    flex-flow: row wrap;
    display: inline-flex;
    margin: 0 0 2em 0 !important;
    dt {
      flex-basis: 10%;
      height: 30px;
      width: 30px;
      text-align: center;
      font: bold 20px/1 Sans-Serif;
      background-color: $black;
      color: $white;
      padding: 5px 0 0 0;
    }
    dd {
      flex-basis: 90%;
      flex-grow: 1;
      padding: 0 0 0 10px !important;
      line-height: 1.5em;
      font-weight: 300;
      border-top: none !important;
      strong {
        font-weight: 400;
        display: inline-block;
        font-size: 1.25em;
        &:after {
          content: '';
          display: block;
          height: 3px;
          width: 0;
          margin: 0;
          background: transparent;
          transition: width 0.25s ease, background-color 0.25s ease;
        }
      }
      &:hover {
        &:before {
          transition: all .25s;
          background-color: $orange;
          font: bold 20px/1 Sans-Serif;
        }
        strong {
          color: $orange;
          &:after {
            width: 100%;
            background: $orange;
          }
        }
      }
    }
  }
}
.partner-blocks {
  padding: 30px;
  .column-block {
    margin: 0px;
    padding: 10px;
  }
  .partner-logo {
    overflow: hidden;
    position: relative;
    margin: 0;
    height: 100%;
    background-color: $white;
    box-shadow: 0 8px 16px -8px $gray;
    img {
      transform: translate3d(0px, -50%, 0px);
      transition: transform .2s ease-in-out;
      margin-bottom: -38%;
    }
    .meta {
      position: absolute;
      transition: opacity .2s ease-in-out;
      opacity: 0;
      bottom: 0;
      color: $white;
      background-color: rgba($black, 0.75);
      width: 100%;
      height: 100%;
      text-align: center;
      span {
        margin-top: 14%;
        display: block;
      }
    }
    &:hover {
      img {
        transform: translate3d(0px, 0%, 0px);
      }
    }
    @include mq(mq-large) {
      &:hover {
        .meta {
          opacity: 1;
        }
      }
    }
  }
}
.photo-gallery {
  .thumbnail {
    margin: .5em!important;
    padding: .5em;
    border-color: $ghost;
    box-shadow: 0 0 0 1px rgba($light-gray,.2);
    img {
      filter: grayscale(100%);
      transition: all .25s ease-in-out;
      &:hover {
        filter: grayscale(0%);
      }
    }
  }
}
// APPROACH
.notes {
  z-index: 999999;
  .is-open {
    line-height: 2em !important;
    padding: 1em;
    font-size: 0.8em !important;
    z-index: 99999 !important;
    background-color: $eggshell !important;
    border: none !important;
    @include radius;
    h4 {
      display: block;
      border-bottom: 1px solid darken($eggshell, 20%);
      border-width: 50%;
      text-align: center;
      margin-bottom: 1em;
    }
    p {
      color: $gray;
      font-size: 1.1em !important;
    }
    @include mq(mq-medium) {
      padding: 2em !important;
      width: 40% !important;
    }
  }
}
.approach-pathway {
  position: relative;
  .approach-image {
    //position: absolute;
    z-index: 0;
    width: 100%;
  }
}
.info-bubbles {
  display: none;
  z-index: 1;
  font-family: $body-font-family !important;
  display: block;
  .title {
    background-color: $navy-blue;
    background: linear-gradient(to right, rgba($blue, 1) 0%, rgba($navy-blue, 1) 100%);
    color: $white;
    padding: 4px 16px 4px 20px;
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
    font-size: 0.8em;
    display: inline-block;
    transition: all .25s;
    @include mq(mq-medium) {
      font-size: 0.95em;
      padding: 6px 16px 6px 20px;
    }
    @include mq(mq-large) {
      font-size: 1em;
      padding: 6px 16px 6px 24px;
    }
    &:hover {
      background-color: $orange;
      background: linear-gradient(to left, rgba($blue, 1) 0%, rgba($navy-blue, 1) 100%);
    }
  }
  i {
    color: $orange;
    z-index: 1;
    //display: inline;
    &:before {
      margin: 0;
      font-size: 30px;
      text-align: center !important;
      width: 35px;
      display: inline-block;
      opacity: 0;
      color: $orange;
      -webkit-animation: pulse 3s infinite;
      -moz-animation: pulse 3s infinite;
      -o-animation: pulse 3s infinite;
      animation: pulse 3s infinite;
    }
    &:hover {
      &:before {
        -webkit-animation-play-state: paused;
        -moz-animation-play-state: paused;
        -o-animation-play-state: paused;
        animation-play-state: paused;
      }
    }
  }
  @include mq(mq-medium) {
  }
  @include mq(mq-xlarge) {
    top: 180px;
  }
}
.bubble-01 {
  position: absolute;
  top: 65%;
  left: 11%;
  i:before {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
}
.bubble-02 {
  position: absolute;
  top: 53%;
  left: 21%;
}
.bubble-03 {
  position: absolute;
  top: 35%;
  left: 30%;
  i:before {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
}
.bubble-04 {
  position: absolute;
  top: 22%;
  left: 40%;
}
.bubble-05 {
  position: absolute;
  top: 7%;
  left: 48%;
  i:before {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
}
.mountain-reflect {
  position: relative;
  width: 100%;
  height: 100%;
  //background: url('/assets/images/site/approach.jpg') center center repeat;
  //background: url('/assets/images/site/approach-rev.jpg') top center repeat;
  color: $white !important;
  h3 {
    color: $white !important;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top right, rgba($sage, 1) 0%, rgba($navy-blue, 1) 100%);
    //filter:saturate(8) ;
  }
}
.challenge-stories {
  h4 {
    margin: 0;
    @include header-size(h3);
  }
  em {
    color: $gray;
    font-size: 1.25em;
    font-family: $font-family-serif;
  }
  .panel {
    background-color: $vapor;
    @include radius;
    border: none;
  }
}
.stak-stories {
  @include stak;
  -webkit-box-shadow: 0 -8px 30px 0 rgba(50, 50, 50, 0.3);
  -moz-box-shadow: 0 -8px 30px 0 rgba(50, 50, 50, 0.3);
  box-shadow: 0 -8px 30px 0 rgba(50, 50, 50, 0.3);
  padding: 0 !important;
  background-image: url('/assets/images/site/bgd-topo.webp');
  h4 {
    margin: 0;
    @include header-size(h3);
  }
  em {
    color: $gray;
    font-size: 1.25em;
    font-family: $font-family-serif;
  }
  p {
    font-size: 0.9em;
  }
  .fill-image {
    height: 320px;
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: top center;
    @include mq(mq-medium) {
      height: 640px;
      background-size: cover;
    }
    @include mq(mq-xlarge) {
      height: 800px;
    }
  }
  .vertical-center {
    background-image: url('/assets/images/site/bgd-topo.webp');
    // &:before {
    //    content: url('/assets/images/site/angle.png');
    //    width: 184px;
    //    height:502px;
    //    position: absolute;
    //    left: -150px;
    //    bottom:0;
    //    transform: scaleX(-1);
    //
    // }
  }
  .v-center {
    padding: 1em;
  }
  @include mq(mq-medium) {
    margin-bottom: 0;
  }
  @include mq(mq-large) {
    p {
      font-size: 0.9em;
      &.lead {
        font-size: 125%;
        line-height: 1.6;
      }
    }
    .v-center {
      width: 90%;
      left: 5%;
    }
  }
  @include mq(mq-xlarge) {
    p {
      font-size: 1em;
    }
    .v-center {
      width: 80%;
      left: 10%;
    }
  }
}
// WORK
/////////////////////////////////////////////////////////////////////////
#projectsCaseContainer {
  background-color: $graphite;
}
#grid {
  margin: 0;
  padding: 0;
  .element {
    margin: 0 0 0 -1px;
    padding: 0;
    transition: all 0.3s;
    display: block;
    overflow: hidden;
    text-align: center !important;
    cursor: pointer;
    float: none !important;
  }
}
.project-card-slider {
  margin-bottom: 0;
  .slick-slide {
    line-height: 0;
  }
}
.project-cards {
  article {
    position: relative;
    overflow: hidden;
    .content {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      opacity: 1;
      transition: all 0.4s;
      background-color: rgba($graphite, 0.15);
      margin: 0;
      padding: 0;
      > div {
        display: table;
        height: 100%;
        width: 100%;
        > div {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          height: 100%;
          transition: 0.5s ease-in-out;
          transform: translate(0px, 30px);
          img {
            transition: all 0.5s;
            transition-delay: 0.3s;
            opacity: 0;
          }
          h4 {
            transition: all 0.5s;
            transition-delay: 0.3s;
            opacity: 1;
            display: none;
            padding: 4px 8px;
            color: $white;
            @include mq(mq-medium) {
              background-color: transparent;
              opacity: 0;
            }
          }
          p, .button-group {
            transition: opacity 0.5s;
            transition-delay: 0.5s;
            opacity: 1;
            margin: 1em 2em;
            font-size: 0.750em;
            line-height: 150%;
            //text-align: left;
            color: $white;
            @include mq(mq-medium) {
              opacity: 0;
            }
          }
          .button-group {
            text-align: center !important;
            justify-content: center;
          }
          .more {
            opacity: 0;
          }
          .overlay-logo {
            //height: 245px;
            //width: 320px;
            img {
              opacity: 1;
              width: auto;
              display: block;
              margin: 0 auto;
            }
          }
          .projectsListLink {
            opacity: 0;
          }
          .button.small {
            padding: 0.575rem 1rem 0.7375rem;
          }
          @include mq(mq-medium) {
            .button.small {
              padding: 0.875rem 1.75rem 0.9375rem;
            }
          }
        }
      }
    }
    picture > img {
      height: auto;
      max-width: 100%;
      width: 100%;
      transition: all 0.4s;
    }
  }
  @include mq(mq-medium) {
    &:hover {
      article {
        .content {
          opacity: 1;
          //background-color: rgba($white, 0.65);
          background-color: rgba($blue, 0.75);
          > div > div {
            transform: translate(0px, -10%);
          }
          .button-group,
          .more,
          .projectsListLink,
          h4,
          img,
          p {
            opacity: 1;
            display: block;
            color: $eggshell;
          }
          h4 {
            font-weight: bold;
          }
          @include mq(mq-xlarge) {
            h4 {
              @include header-size(h3);
            }
          }
          .overlay-logo {
            img {
              opacity: 0;
              width: auto;
            }
          }
        }
        picture > img {
          transform: scale(1.5, 1.5);
        }
      }
    }
  }
}
//// project cards
//.project-cards {
//  article {
//    margin: 0 0 0 -1px;
//    padding: 0;
//    transition: all 0.3s;
//    display: block;
//    overflow: hidden;
//    text-align: center !important;
//    cursor: pointer;
//    position: relative;
//    .content {
//      position: absolute;
//      right: 0;
//      top: 0;
//      bottom: 0;
//      height: 100%;
//      width: 100%;
//      opacity: 1;
//      transition: all 0.4s;
//      background-color: rgba($graphite, 0.15);
//      margin: 0;
//      padding: 0;
//      //background-color: rgba($blue, 0.75);
//      > div > div {
//        transition: 0.5s ease-in-out;
//        transform: translate(0px, 30px);
//      }
//      .button-group,
//      .more,
//      .projectsListLink,
//      h4,
//      img,
//      p {
//        opacity: 1;
//        display: block;
//        color: $eggshell;
//      }
//      h4 {
//        font-weight: bold;
//      }
//      @include mq(mq-xlarge) {
//        h4 {
//          @include header-size(h3);
//        }
//      }
//      //.overlay-logo {
//      //  img {
//      //    opacity: 0;
//      //    width: auto;
//      //  }
//      //}
//      .overlay-logo {
//        //height: 245px;
//        //width: 320px;
//        img {
//          opacity: 1;
//          width: auto;
//          display: block;
//          margin: 0 auto;
//        }
//      }
//    }
//    picture > img {
//      transform: scale(1.5, 1.5);
//    }
//  }
//  &:hover {
//    article {
//      .content {
//        opacity: 1;
//        //background-color: rgba($white, 0.65);
//        background-color: rgba($blue, 0.75);
//        > div > div {
//          transform: translate(0px, -10%);
//        }
//        .overlay-logo {
//          img {
//            opacity: 0;
//            width: auto;
//          }
//        }
//      }
//    }
//  }
//}
.work-external-link {
  display: none;
}
.options {
  padding-bottom: 1.0em;
  h3 {
    margin-bottom: 0.2em;
    font-size: 15px;
    display: inline;
    img {
      margin: 0 4px 0 0;
    }
  }
  h4 {
    font-weight: bold;
  }
  ul {
    margin: 0;
    list-style: none;
    border: none;
    display: inline;
    li {
      margin: 0.25em 0;
      display: inline-block;
      a {
        //font-size: 1em;
        display: block;
        background-color: transparent;
        color: $gray;
        font-family: "futura-pt", Helvetica, Arial, sans-serif;
        font-size: 0.8em;
        padding: 0.75em;
        line-height: 1em;
        border: 1px solid $silver;
        border-radius: 2.375em;
        transition: all 0.25s;
        @include mq(mq-large) {
          font-size: 1.1em;
        }
        &:hover {
          //  background-color: #fff;
          border: 1px solid $orange;
        }
        &:active {
          color: $orange;
          background-color: transparent;
          border: 1px solid $orange;
        }
        &:first-child {
          //   border-radius: 4px 0 0 4px;
          //   border-left: none;
        }
        &:last-child {
          //  border-radius: 0 4px 4px 0;
        }
        &.selected {
          color: $orange;
          border: 1px solid $orange;
        }
        &.selected:hover {
          //  background-color: #fff;
        }
      }
    }
    ul {
      margin-left: 1.5em;
    }
  }
  .option-set {
    width: 100%;
  }
}
.mini-case {
  .right {
    a {
      font-size: 1.5em;
      color: $white;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .projectsListLink {
    @include button-group();
    // a {
    //     @include button;
    //  }
  }
  h5 {
    border-bottom: 1px solid rgba($white, 0.25);
  }
}
h6 {
  float: left;
}
.project-icons {
  list-style-type: none;
  margin: 0 0 1em 0;
  padding: 0;
  li {
    display: inline-block;
    padding: 0 8px 0 0;
    font-size: .9em;
    color: lighten($blue, 30%);
  }
}
.project-mini-screenshot {
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid rgba(254, 254, 254, 0.25);
}
.off-canvas-fixed {
  transition: transform 500ms ease;
  z-index: 99999;
}
.offcanvas-overlap-left > .right-off-canvas-menu {
  transform: translate3d(-$off-canvas-width, 0, 0);
}
.left-off-canvas-menu {
  display: none;
  // Hide all off-canvas menus by default...
  overflow: auto;
  height: 100%;
  width: 100%;
  position: fixed;
  @include mq(mq-medium) {
    width: 100%;
  }
  @include mq(mq-large) {
    width: 60%;
  }
  // ...but show the ones that have this class.
  &.off-canvas-visible {
    display: block;
  }
  //background-color: rgba($graphite, .9);
  background-color: rgba($graphite, 0.98);
  .content {
    overflow: auto;
    height: 100vh;
    //width: 90%;
    padding: 1em;
    color: $white;
    p {
      font-size: 0.9em;
    }
    h2,
    h3,
    h4 {
      color: $white;
      display: block;
    }
    h5 {
      color: $blue;
    }
  }
}
.offcanvas-overlap-right > .left-off-canvas-menu {
  transform: translate3d($off-canvas-width, 0, 0);
}
// CONTACT FORM
// GARY, THIS FONT FACE IS ONLY USED FOR THE ARROW. FEEL FREE TO SWITCH IT TO USE ONE OF FOUNDATIONS BUILT-IN ICONS
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?-9heso");
  src: url("../fonts/icomoon/icomoon.eot?#iefix-9heso") format("embedded-opentype"), url("../fonts/icomoon/icomoon.woff?-9heso") format("woff"), url("../fonts/icomoon/icomoon.ttf?-9heso") format("truetype"), url("../fonts/icomoon/icomoon.svg?-9heso#icomoon") format("svg");
}
.aktivForm {
  position: relative;
  margin: 0 auto;
  padding: 1em 0 !important;
  max-width: 860px;
  width: 100%;
  text-align: left;
  font-size: 1.5em;
  .questions {
    li {
      z-index: 100;
      position: relative;
      visibility: hidden;
      height: 0;
      -webkit-transition: visibility 0s 0.4s, height 0s 0.4s;
      transition: visibility 0s 0.4s, height 0s 0.4s;
      > span {
        display: block;
        overflow: hidden;
        label {
          display: block;
          -webkit-transition: -webkit-transform 0.4s;
          transition: transform 0.4s;
          -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
        }
      }
      &.current > span label,
      .no-js .questions li > span label {
        -webkit-transition: none;
        transition: none;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      &.current,
      .no-js .questions li {
        visibility: visible;
        height: auto;
        -webkit-transition: none;
        transition: none;
      }
    }
    label {
      font-size: 1.5rem;
      text-align: center;
    }
    input {
      display: block;
      margin: 0.3em 0 0;
      padding: 0.5em 1em 0.5em 0.7em;
      width: calc(100% - 2em);
      border: none;
      background: transparent;
      color: rgba(0, 0, 0, 0.8);
      font-size: 1em;
      line-height: 1;
      opacity: 0;
      -webkit-transition: opacity 0.3s;
      transition: opacity 0.3s;
      box-shadow: none;
    }
    .current input,
    .no-js .questions input {
      opacity: 1;
    }
    .aktivForm button:focus,
    input:focus {
      outline: none;
    }
  }
  .show-next .questions li.current > span label {
    -webkit-animation: moveUpFromDown 0.4s both;
    animation: moveUpFromDown 0.4s both;
  }
  @-webkit-keyframes moveUpFromDown {
    from {
      -webkit-transform: translateY(100%);
    }
    to {
      -webkit-transform: translateY(0);
    }
  }
  @keyframes moveUpFromDown {
    from {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
    to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  .submit {
    display: none;
  }
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    -webkit-transition: height 0.4s;
    transition: height 0.4s;
    &::before {
      content: '';
      background-color: rgba(0, 0, 0, 0.1);
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2.35em;
    }
  }
  .progress {
    width: 0;
    height: 0.15em;
    background: rgb(252, 67, 4);
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    margin-bottom: initial;
    padding: initial;
    border: none;
    &::before {
      position: absolute;
      top: auto;
      width: 100%;
      height: inherit;
      background: rgba(0, 0, 0, 0.05);
      content: '';
    }
  }
  .number {
    position: absolute;
    right: 0;
    overflow: hidden;
    margin: 0.4em 0;
    width: 3em;
    font-weight: 700;
    font-size: 0.4em;
    &:after {
      position: absolute;
      left: 50%;
      content: '/';
      opacity: 0.4;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    span {
      float: right;
      width: 40%;
      text-align: center;
    }
    .number-current {
      float: left;
    }
  }
  /* Next question button */
  .next {
    position: absolute;
    right: 0;
    bottom: 2.5em;
    /* padding-bottom of form plus progress bar height */
    display: block;
    padding: 0;
    width: 2em;
    height: 2em;
    border: none;
    background: none;
    color: rgba(0, 0, 0, 0.4);
    margin: 0 5px -28px 0;
    text-align: center;
    opacity: 0;
    z-index: 100;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%);
    pointer-events: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &:hover {
      color: #ffffff;
      background-color: #d53803;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "\e600";
      text-transform: none;
      font-weight: normal;
      font-style: normal;
      font-variant: normal;
      font-family: 'icomoon';
      line-height: 2;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    &.show {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
      pointer-events: auto;
    }
  }
  .number-next {
    position: absolute;
    left: 0;
  }
  &.show-next {
    .number-current {
      -webkit-transition: -webkit-transform 0.4s;
      transition: transform 0.4s;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    .number-next {
      -webkit-animation: moveUpFromDown 0.4s both;
      animation: moveUpFromDown 0.4s both;
    }
  }
  //Form Messages
  .final-message {
    top: 50%;
    left: 0;
    padding: 0.5em;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 1em;
    &.show {
      -webkit-transition-delay: 0.5s;
      transition-delay: 0.5s;
    }
    &.hide {
      visibility: hidden;
      opacity: 0;
      -webkit-transition: opacity 0.3s, visibility 0s 0.3s;
      transition: opacity 0.3s, visibility 0s 0.3s;
    }
  }
  .error-message {
    padding: 0.4em 3.5em 0 0;
    width: 100%;
    color: rgba(0, 0, 0, 0.7);
    font-style: italic;
    font-size: 0.4em;
  }
  .error-message,
  .final-message {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;
    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
  // No JS FALLBACK
  .no-js {
    .aktivForm {
      font-size: 1.75em;
      .questions {
        li {
          padding: 0 0 2em;
        }
      }
      .submit {
        display: block;
        float: right;
        padding: 10px 20px;
        border: none;
        background: rgba(0, 0, 0, 0.3);
        color: rgba(0, 0, 0, 0.4);
      }
      .controls {
        display: none;
      }
    }
  }
}
ul.errors {
  margin: 0;
  li.form-error {
    display: block;
    &:before {
      content: "*";
    }
  }
}
.bgd-map {
  background: url("/assets/images/site/bgd-map-mobile.webp") no-repeat;
  background-repeat: repeat;
  background-size: initial;
  background-position: center center;
  .stak {
    padding: 0 !important;
  }
  @include mq(mq-medium) {
    background: url("/assets/images/site/bgd-map.webp") no-repeat;
    background-size: fill;
  }
  @include mq(mq-large) {
    background-size: cover;
  }
}
// RESOURCES
//////////////////////////////////////////////////////////////////////////
figure {
  //text-align: center;
  margin: 0 0 0.5em;
  position: relative;
  figcaption {
    color: $steel;
    font-size: 0.9em;
    margin: 0.5em 0;
      position: absolute;
      bottom: -2em;
      left: 0;
      background-color: $white;
      padding: 1em;
      display: block;
      box-shadow: -8px 8px 12px 0px rgba($gray, 0.15);
      @include breakpoint(large) {
        padding: 1.5em;
      }
  }
}
// ARTICLES
blockquote, .blockquote {
  margin: 30px 0;
  text-align: center;
  border-left: none;
  p {
    margin: 0;
    letter-spacing: 0.1em;
    &:before {
      content: '“';
    }
    &:after {
      content: '”';
    }
  }
  footer {
    margin: 10px 0;
    font-style: italic;
    font-family: $font-family-serif;
  }
  @include mq(mq-medium) {
    p {
      @include header-size(h3);
      font-size: 46px;
    }
  }
}
.blog,
.casestudy,
.approach,
.about {
  footer.authorinfo {
    p {
      color: $gray;
      font-family: $font-family-serif;
    }
  }
  .breakout {
    float: right;
    width: 45% !important;
    padding: 0 0 2em 2em;
    @include mq(mq-medium) {
      width: 25% !important;
    }
  }
  .pullquote {
    padding: 0.5em 0;
    p {
      border-top: 3px solid $black;
      border-bottom: 3px solid $black;
      padding: 1em 0.25em;
      color: $gray;
      text-align: center;
      @include header-size(h3);
    }
  }
  .columns.right {
    padding-right: 0;
  }
  .columns.left {
    padding-left: 0;
  }
  dl {
    margin: 2em auto;
    dt {
      margin-bottom: 0;
    }
    dd {
      border-top: 1px solid $silver;
      margin-bottom: 1em;
      padding-top: 0.5em;
    }
  }
}
.blog-entry {
  text-align: center;
  @include mq(mq-medium) {
    text-align: left;
  }
  figure {
    overflow: hidden;
    //max-width: inherit;
    max-height: 260px;
    width: 100%;
    a > img {
      width: 100%;
      transition: all 0.25s;
      max-width: initial;
    }
  }
  &:hover {
    figure {
      a img {
        width: 110%;
      }
    }
  }
  .summary-wrap {
    h3 {
      margin-bottom: 0.25em;
    }
    h4 {
      @include header-size(h5);
    }
    .author {
      font-style: italic;
      padding-right: 10px;
      //padding-bottom: 20px;
      display: inline-block;
      font-family: $font-family-serif;
    }
    .separator {
      padding-right: 10px;
    }
    .more-link {
      text-transform: uppercase;
      letter-spacing: 1px;
      //padding-right: 30px;
      display: inline-block;
      position: relative;
      font-size: .8em;
      &:after {
        content: '';
        display: block;
        height: 3px;
        width: 0;
        margin: 0.25em 0 0;
        background: transparent;
        transition: width 0.25s ease, background-color 0.25s ease;
      }
      &:hover:after {
        width: 100%;
        background: $orange;
      }
    }
  }
}
article.blog-entry {
  @include grid-column(6);
  @include mq(mq-medium) {
    @include grid-column(4);
  }
  @include mq(mq-large) {
    @include grid-column(6);
  }
  padding: 1em 0;
  figure {
    //overflow: hidden;
    padding: 1em;
    max-height: initial;
    @include grid-column(12);
    @include mq(mq-medium) {
      @include grid-column(12);
      padding: 0;
      a img {
        width: 100%;
      }
    }
    @include mq(mq-large) {
      @include grid-column(5);
    }
  }
  &:hover {
    figure {
      a img {
        width: 100%;
      }
    }
  }
  .summary-wrap {
    @include grid-column(12);
    @include mq(mq-medium) {
      @include grid-column(12);
      h3 {
        font-size: f-header-size(medium, h3) - 0.5;
      }
    }
    @include mq(mq-large) {
      @include grid-column(7);
      h3 {
        font-size: f-header-size(medium, h3);
      }
    }
  }
  &.featured {
    @include grid-column(12);
    //display: flex;
    align-items: center;
    margin-bottom: 60px;
    border-bottom: 4px solid $silver;
    figure {
      margin-bottom: 1em;
      @include grid-column(12);
      @include mq(mq-medium) {
        @include grid-column(6);
      }
      a img {
        max-height: 100%;
      }
    }
    .summary-wrap {
      @include grid-column(12);
      @include mq(mq-medium) {
        @include grid-column(6);
      }
    }
  }
  &.horizontal {
    @include grid-column(12);
    //display: flex;
    align-items: center;
    margin: 1em auto 0 auto;
    border-bottom: 4px solid $silver;
    figure {
      margin-bottom: 1em;
      @include grid-column(12);
      @include mq(mq-medium) {
        @include grid-column(4);
      }
      a img {
        max-height: 100%;
      }
    }
    .summary-wrap {
      @include grid-column(12);
      @include mq(mq-medium) {
        @include grid-column(8);
      }
    }
  }
}
.blog {
  .aside {
    @include stak;
    .latest-articles {
      margin-bottom : 2em;
      p {
        font-size: .9em;
        margin-bottom: 0;
        line-height: 1.25em;
      }
      .summary-wrap {
        padding-left: 1em;
      }
    }
    figure {
      margin: 0;
    }
    article {
      margin-bottom: .75em;
      padding-bottom: .75em;
      border-bottom: 1px solid $light-gray;
    }
  }
  .page-content {
    section.stak {
      > .row {
        &:nth-child(even) {
          // background-color: $vapor;
        }
      }
    }
    p, ol, li {
      font-size: 1em;
      @include mq(mq-large) {
        font-size: 1.1em;
      }
    }
    ol {
      margin-left: 1.5em;
      line-height: 1.35em;
      color: $steel;
      list-style: none;
      counter-reset: li;
      li {
        font-size: 1em;
        margin-bottom: 1em;
        counter-increment: li;
        &:before {
          content: counter(li);
          color: red;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
        strong {
          font-size: 1.15em;
          color: $orange;
        }
      }
    }
    ul {
      line-height: 1.35em;
      color: $steel;
      list-style: none; /* Remove default bullets */
      li {
        margin-bottom: 1em;
        &:before {
          content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: $primary-color; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 1em; /* Also needed for space (tweak if needed) */
          margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
      }
    }
    .callout {
      background-color: $light-gray;
      margin: 1em;
      padding: 2em;
    }
    blockquote {
      float: right;
      border-top: 3px solid $black;
      border-bottom: 3px solid $black;
      padding: 1em 0.25em;
      color: $gray;
      text-align: center;
      @include header-size(h3);
      width: 45% !important;
      margin: 0 -1em 1em 1em;
      @include mq(mq-medium) {
        width: 25% !important;
      }
    }
  }
}
.column {
  @include breakpoint(medium) {
    > .right {
      margin: 0;
      float: right;
      max-width: 50%;
      padding-left: .5em;
    }
    > .left {
      margin: 0;
      float: left;
      max-width: 50%;
      padding-right: .5em;
    }
  }
}
#main .paginate {
  margin-bottom: 50px;
  display: block;
  text-align: center;
  ul {
    display: inline-block;
    text-align: center;
    margin-top: 0;
  }
  li {
    display: inline-block;
    text-align: center;
    padding: 5px;
    &.current {
      color: $blue;
      border-bottom: 2px solid $red;
    }
  }
}
figure.img-right {
  margin: 0 auto 20px auto;
  width: 80%;
  max-width: auto;
  @include mq(mq-medium) {
    float: right;
    margin-left: 40px;
    width: 30%;
  }
  @include mq(mq-large) {
    float: right;
    margin-left: 60px;
    width: 40%;
  }
  &.img-left {
    float: left;
  }
}
#main .prev-next-entry {
  display: block;
  text-align: center;
  ul {
    display: inline-block;
    text-align: center;
    margin: 0;
  }
  li {
    display: inline-block;
    text-align: center;
    padding: 10px;
  }
}
.pag-next-link,
.pag-prev-link {
  //width: 20px;
  height: auto;
  font-size: 1.5em;
  position: relative;
  display: block;
  span {
    font-size: 0;
  }
  &:after {
    display: block;
    content: "\e105";
    font-family: "Flaticon";
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    margin-top: -18px;
    //background-size: 20px 20px;
  }
}
.casestudy {
  .pag-next-link,
  .pag-prev-link {
    width: auto;
    div {
      margin: auto;
    }
  }
}
.pag-prev-link {
  &:after {
    left: -35px;
    transform: rotate(180deg);
  }
  div {
    margin: 0 0 0 30px;
    padding-top: 8px;
    font-size: 0.7em;
  }
}
.pag-next-link {
  &:after {
    right: -35px;
    margin-top: -16px;
  }
  div {
    margin: 10px 0 0 -40px;
    padding-top: 8px;
    font-size: 0.7em;
  }
}
.case-next-link,
.case-prev-link {
  //width: 20px;
  height: auto;
  font-size: 1em;
  position: relative;
  display: block;
  &:hover {
    text-decoration: underline;
  }
  &:after {
    display: block;
    font-family: "Flaticon";
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    margin-top: -16px;
    font-size: 1.35em;
    //background-size: 20px 20px;
  }
}
.case-next-link {
  margin-right: 35px;
  &:after {
    right: -35px;
    margin-top: -18px;
    content: "\e008";
  }
}
.case-prev-link {
  margin-left: 35px;
  &:after {
    left: -35px;
    content: "\e009";
  }
}
.case-slider {
  @include case-slide;
  margin-top: 0;
  margin-left: 0;
  transition: opacity 0.25s ease-in;
  background-color: transparent;
  img {
    width: 100%;
  }
  &.slick-slider {
    margin-bottom: 0;
  }
  &.slick-initialized {
    opacity: 1;
    display: block;
  }
  @include mq(mq-xlarge) {
    .slick-next,
    .slick-prev {
      top: 50%;
    }
  }
  // .slick-slide class is jQuery generated
  .slick-slide {
    @include case-slide;
    position: relative;
  }
  a.note {
    font-family: Flaticon;
    content: "\e0a8";
    position: absolute;
    top: 20px;
    left: 20px;
    text-align: center;
    color: $white;
    font-size: 1em;
    &:before {
      content: "\e06f";
      font-size: 28px;
      width: 40px;
      background-color: $blue;
      height: 40px;
      line-height: 40px;
      display: block;
    }
    &.hover {
      &:before {
        content: "\e0cf";
        background-color: $sage;
      }
    }
  }
}
.tags {
  padding-bottom: 1.0em;
  p {
    display: inline;
  }
  ul {
    margin: 0 0 0 1.5em;
    list-style: none;
    border: none;
    display: inline;
    li {
      margin: 0.25em 0;
      display: inline-block;
      a {
        //font-size: 1em;
        display: block;
        background-color: transparent;
        color: $gray;
        font-family: "futura-pt", Helvetica, Arial, sans-serif;
        font-size: 0.8em;
        padding: 0.75em;
        line-height: 1em;
        border: 1px solid $silver;
        border-radius: 2.375em;
        transition: all 0.25s;
        &:hover {
          //  background-color: #fff;
          border: 1px solid $orange;
        }
      }
    }
  }
}
.case-footer {
  box-shadow: inset 0px 10px 20px -10px rgba(0, 0, 0, 0.15);
}
// CONTACT PAGE
.LeadQuestion {
  display: none;
}
#ContactFormSubmitButton {
  display: block;
  margin: 0 auto;
  width: 200px;
  padding: 0.85em 1em;
  font-size: 1.25em;
  @include radius;
}
.ContactForm, .module-form {
  textarea {
    //height: 7em;
    border: 2px solid $black;
    box-shadow: none;
    border-radius: 4px;
    //  &::-webkit-input-placeholder {
    //     color: rgba($gray, .8);
    // }
    &:focus {
      border: 3px solid $lime !important;
      box-shadow: none !important;
      transition: all .25s;
    }
  }
  label {
    display: none;
    &.show {
      display: inline-block;
      color: $gray;
    }
  }
  .is-invalid-input:not(:focus) {
    border-bottom: 3px solid $lime !important;
    box-shadow: none !important;
    transition: all .25s;
    background-color: transparent;
  }
  .is-invalid-input:not(:focus)::placeholder {
    color: $graphite;
  }
  input {
    border: none;
    border-bottom: 2px solid $black;
    box-shadow: none;
    transition: all .25s;
  }
  [type='text']:focus {
    border: none;
    border-bottom: 3px solid $lime !important;
    box-shadow: none !important;
    transition: all .25s;
  }
  select {
    border: none;
    border-bottom: 2px solid $black;
    box-shadow: none;
    transition: all .25s;
    background-color: transparent !important;
    color: lighten($graphite, 50%);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28228,42,5%29'></polygon></svg>");
    &:focus {
      border: none;
      border-bottom: 3px solid $lime !important;
      box-shadow: none !important;
      transition: all .25s;
      color: $graphite;
    }
  }
}
.tech-requirements {
  .columns {
    padding-top: 1em;
  }
  .columns:nth-child(odd) {
    background-color: $vapor;
  }
  @include breakpoint(medium) {
    .columns:nth-child(odd) {
      background-color: initial;
    }
    .row:nth-child(odd) {
      background-color: $vapor;
    }
  }
}
// CONTENT BUILDER
.image-slider {
  position: relative;
  .hero-caption {
    position: absolute;
    z-index: 1;
    //top: 0;
    right: 0;
    left: 0;
    width: 90%;
    text-align: center;
    padding: 100px 0 0;
    h1 {
      font-weight: 900;
      margin: 0;
      text-transform: uppercase;
    }
    h2 {
      font-weight: 200;
      margin: 0;
    }
    h1,
    h3 {
      color: white;
      line-height: 1em;
    }
    h2 {
      color: white;
      line-height: normal;
    }
    p {
      font-size: $paragraph-font-size;
      margin-top: 1em;
      a {
        color: white;
        font-weight: 700;
      }
    }
    .button {
      @include button;
      margin-top: 2em;
    }
  }
  @include mq(mq-medium) {
    .hero-caption {
      padding: 120px 0;
      p {
        font-size: $paragraph-font-size + 0.25;
      }
      .button {
        @include button;
        margin-top: 2em;
      }
    }
  }
  @include mq(mq-large) {
    .hero-caption {
      padding: 220px 0 0;
      h1 {
        font-size: f-header-size(medium, h1) + 0.5;
      }
      h2 {
        @include header-size(h2);
      }
    }
  }
}
// ANIMATIONS
//////////////////////////////////////////////////////////////////////////
.animate {
  opacity: 0;
}
.slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(-2%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
// Pulse Animation For Info Bubbles
@keyframes pulse {
  0%,
  100% {
    font-size: 20px;
    opacity: 0.5;
  }
  50% {
    font-size: 25px;
    opacity: 0.95;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#design-ico:hover .cls-2 path,
#marketing-automation-ico:hover .cls-2 path,
.red-fill {
  fill: $primary-color;
  transition: all 0.5s;
}
#analytics-ico:hover .cls-1 path,
#development-ico:hover .cls-2 path,
.red-stroke {
  stroke: $primary-color;
  transition: all 0.5s;
}
// Greater than 320px (Touch Device Tween States) //
@media only screen and (min-width: 21em) {
  // DEFAULT OVERRIDES
  //////////////////////////////////////////////////////////////////////////
  // GLOBALOVERRIDES
  //////////////////////////////////////////////////////////////////////////
  // HOMEPAGE OVERRIDES
  //////////////////////////////////////////////////////////////////////////
}
// Greater than 640px ( @mid-screen ) //
@media only screen and (min-width: 40.063em) {
  // DEFAULT OVERRIDES
  //////////////////////////////////////////////////////////////////////////
  // GLOBALS OVERRIDES
  //////////////////////////////////////////////////////////////////////////
  // HOMEPAGE OVERRIDES
  //////////////////////////////////////////////////////////////////////////
}
// Greater than 1024px  (geater than grid @mq-large) //
@media only screen and (min-width: 64.063em) {
  // DEFAULT OVERRIDES
  //////////////////////////////////////////////////////////////////////////
  // GLOBAL OVERRIDES
  //////////////////////////////////////////////////////////////////////////
  // HOMEPAGE OVERRIDES
  //////////////////////////////////////////////////////////////////////////
}
// Greater than 1440px (geater than grid @mq-xlarge) //
@media only screen and (min-width: 90.063em) {
  // DEFAULT OVERRIDES
  //////////////////////////////////////////////////////////////////////////
  // GLOBAL OVERRIDES
  //////////////////////////////////////////////////////////////////////////
  // HOMEPAGE OVERRIDES
  //////////////////////////////////////////////////////////////////////////
}
// Greater than 1920px (geater than grid @mq-xxlarge) //
@media only screen and (min-width: 120.063em) {
  // DEFAULT OVERRIDES
  //////////////////////////////////////////////////////////////////////////
  // GLOBAL OVERRIDES
  //////////////////////////////////////////////////////////////////////////
  // HOMEPAGE OVERRIDES
  //////////////////////////////////////////////////////////////////////////
}
